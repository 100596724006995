import { createTheme } from "@mui/material/styles";

import { colors } from "@puzzle/theme";

import { TableCellClass, TableContainerStyle, TableHeaderCellClass } from "./DataGrid.css";

export const dataGridTheme = createTheme({
  palette: {
    primary: {
      main: colors.greenalpha,
    },
    secondary: {
      main: colors.neutral400,
    },
    background: {
      default: colors.mauve800,
    },
    text: {
      primary: colors.neutral300,
      secondary: colors.neutral400,
    },
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        color: "secondary",
        size: "small",
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: "secondary",
        fontSize: "small",
      },
    },
  },
});

export const tableSpecificThemes = {
  mrtTheme: {
    baseBackgroundColor: colors.mauve800,
  },
  muiTableBodyCellProps: {
    className: TableCellClass,
  },
  muiTableHeadCellProps: {
    className: TableHeaderCellClass,
  },
  muiTablePaperProps: {
    className: TableContainerStyle,
  },
  muiSelectCheckboxProps: {
    sx: {
      "&.Mui-checked .MuiSvgIcon-root": {
        color: colors.green500,
      },
    },
  },
  displayColumnDefOptions: {
    "mrt-row-expand": {
      size: 60,
      grow: false,
    },
  },
};
